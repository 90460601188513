.sidebar {
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: rgb(0, 170, 80);
  overflow: auto;
  display: flex;
  justify-content: space-between;
  padding: 0 10% 0 10%;
}
.sidebar .view-logo img{
  display: flex;
  height: 60px;
  width: auto;
}


.sidebar .links {
  display: flex;
  width: 100%;
}

.sidebar .link-profile {
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  max-width:350px ;
}

.sidebar .link-profile img {
  width:50px;
  height:50px;
  display: block;
  margin: auto;
  border-radius: 50%;
  object-fit: cover;
  filter: drop-shadow(2px 3px 6px #121212);
  -webkit-filter: drop-shadow(2px 3px 6px #121212);
}

.sidebar .link-profile span{
  font-weight: bold;
  color: #fff;
}

.sidebar .link-profile button {
  background-color: transparent;
  border: 0;
  padding: 5px;
  border-radius: 2px;
  
}

.sidebar .link-profile button:hover{
  background-color: rgba(255, 0, 0, 0.7)
} 

.sidebar a {
  display: block;
  padding: 16px;
  display: flex;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.8);
  flex-direction: row;
  align-items: center;
  transition: ease-in-out 0.4s;
}

.sidebar a svg {
  margin-right: 0.5rem;
}

.sidebar a:hover {
  /* background-color: #121212; */
  background-color: rgba(50,50,50, .5);
  color: #fff;
}

.content {
  padding: 1px 16px;
}

.link-login{
  gap: 5px;

}

@media screen and (max-width: 700px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }

  div.content {
    margin-left: 0;
  }

  .sidebar a {
    float: left;
  }

  .sidebar div {
    display: none;
  }

  .sidebar a svg {
    display: none;
  }
}

@media screen and (max-width: 400px) {
  .sidebar a {
    text-align: center;
    float: none;
  }

  .sidebar a svg {
    display: none;
  }
}
