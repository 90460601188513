.bottombar {
display: flex;
flex-direction: column;
  width: 100%;
  margin: 0;
  padding: 0;
    position: absolute;
  background-color: rgb(0, 170, 80);
    margin-top: 20%;
  display: flex;
  justify-content: space-between;
  padding: 0 10% 0 10%;
}

.bottombar .view-logo img {
  display: flex;
  height: 60px;
  width: auto;
}

.bottombar .links {
  display: flex;
  width: 100%;
}

.bottombar .link-profile {
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  max-width: 350px;
}

.bottombar .link-profile img {
  width: 50px;
  height: 50px;
  display: block;
  margin: auto;
  border-radius: 50%;
  object-fit: cover;
  filter: drop-shadow(2px 3px 6px #121212);
  -webkit-filter: drop-shadow(2px 3px 6px #121212);
}

.bottombar .link-profile span {
  font-weight: bold;
  color: #fff;
}

.bottombar .link-profile button {
  background-color: transparent;
  border: 0;
  padding: 5px;
  border-radius: 2px;
}

.bottombar .link-profile button:hover {
  background-color: rgba(255, 0, 0, 0.7);
}

.bottombar a {
  display: block;
  padding: 16px;
  display: flex;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.8);
  flex-direction: row;
  align-items: center;
  transition: ease-in-out 0.4s;
}

.bottombar a svg {
  margin-right: 0.5rem;
}

.bottombar a:hover {
  background-color: #121212;
  color: #fff;
}

.content {
  padding: 1px 16px;
}

@media screen and (max-width: 700px) {
  .bottombar {
    width: 100%;
    height: auto;
    position: relative;
  }

  div.content {
    margin-left: 0;
  }

  .bottombar a {
    float: left;
  }

  .bottombar div {
    display: none;
  }

  .bottombar a svg {
    display: none;
  }
}

@media screen and (max-width: 400px) {
  .bottombar a {
    text-align: center;
    float: none;
  }

  .bottombar a svg {
    display: none;
  }
}
