.content{
    
    padding: 0 10% 0 10%;
  }
.view-details-selective-process{
    margin-top: 10%;
    display: flex;
    flex-direction: column;
}

.view-details-selective-process .view-details-selective-process-labels{
    display: flex;
    flex-direction: column;
}

.view-details-selective-process .view-details-selective-process-labels label {
    font-weight: bold;
    
}

.view-details-selective-process .view-details-selective-process-labels label span{
    font-weight: normal;
    
}

.view-details-selective-process .view-details-selective-process-complement{
    margin-top: 20px;
}

.view-details-selective-process .view-details-selective-process-complement ul{
    list-style: disc;
    padding: 10px;
}

.view-details-selective-process .view-details-selective-process-complement ul li{
margin: 0 0 0 10%;
}

