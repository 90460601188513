

.content {
  padding: 0 10% 0 10%;
  overflow: auto;
}

.view-cards-process {
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 100%;
  /* background-color: #Aaa; */
  padding: 15px;
}

.card-process-item {
  display: flex;
  flex-direction: column;
  border: 1px solid rgb(50, 50, 50);
  color: rgb(50, 50, 50);
  padding: 10px;
  border-radius: 10px;
  position: relative;
  transition: all 0.35s;
}
.card-process-item:hover {
  transform: scale(1.03);
  border: 1px solid rgb(0, 170, 80);
}
.card-process-item:hover .view-title-process {
  border-bottom: 1px solid rgb(0, 170, 80);
}

.view-cards-process .card-process-view-status {
  position: absolute;
  right: 0;
 top: 0;
  padding: 5px;
}

.view-title-process {
  border-bottom: 1px solid rgb(80, 80, 80);

  
  margin: 3px 0 8px 0;
}

.view-title-process span {
  font-size: 1.3em;
  font-weight: bold;
}



.card-process-item .view-date {
  /* position: absolute; */
  width: 100%;
  display: flex;
  flex-direction: column;align-items: end;
  /* right: 0;
  bottom: 0; */

}

.card-process-item .view-date span {
  font-size: 0.9em;
  font-style: italic;
}

.card-process-item span span {
  font-style: italic;
}
