.container-center{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #121212;
  padding: 0 12px;
}

.login{
  background-color: #EAEAEC;
  width: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.login-area{
  background-color: rgb(0,170,80);
  width: 100%;
  display: flex;
  justify-content: center;
}

.login-area img{
  width: auto;
  height: 130px;
  padding: 20px;
}

form{
  margin-top: 1.5rem;
  width: 90%;
  display: flex;
  flex-direction: column;
}

form h1{
  text-align: center;
  margin-bottom: 1rem;
  color: rgb(0,170,80);
}

.login input{
  margin-bottom: 1rem;
  height: 35px;
  border-radius: 4px;
  border:0;
  padding: 10px;
  font-size: 15px;
  background-color: #FFF;
}

form button{
  height: 35px;
  border:0;
  border-radius: 4px;
  background-color: rgb(0,170,80);
  color: #FFF;
  font-size: 1.3rem;
  
}

.login a{
  margin: 1.5rem 0;
  color: #000;
  cursor: pointer;
}