.form-entry-process {
  display: flex;
  flex-direction: column;
  color: rgb(50, 50, 50);
  width: 100%;
  position: relative;
}

.form-entry-process label {
  margin-top: 1%;
}

/* .form-entry-process input {

  border-radius: 5px;
  border: 0.5px solid rgb(50, 50, 50);
} */

.form-entry-process section {
  margin-top: 1%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}
.form-entry-process section div {
  display: flex;
  flex-direction: column;
}

.float-label {
  margin-top: 2%;
  display: flex;
  flex-direction: column;
  min-width: 350px;
  position: relative;
}

.float-label input  {
  display: flex;
  align-items: center;
  border: 0.5px solid rgb(50, 50, 50);
  height: auto;
  font-size: 1em;
  border-radius: 4px;
  background: #fff;
  padding: 5px;
}

.float-label label {
  color: rgb(50, 50, 50);
  font-size: 1em;
  margin-left: 1%;
  padding: 0 2px;
  position: absolute;

  transition: all 0.2s ease-out;
}
.float-label:hover label {
  transform: translate(0, -100%) scale(1);
  z-index: 99;
  background-color: #fff;
  border-radius: 5px;
  padding: 0 10px;

  border: 1px solid rgb(50, 50, 50);
}

.float-label:focus-within label {
  transform: translate(0, -100%) scale(1);
  z-index: 99;
  background-color: #fff;
  border-radius: 5px;
  padding: 0 10px;
  border: 2px solid rgb(0, 170, 80);
}

.float-label input:focus-within {
  outline: none;
  border: 2px solid rgb(0, 170, 80);

  transition: all 0.2s ease-out;
}

.float-label .Active {
  transform: translate(0, -100%) scale(1);
  background-color: #efefef;
  border-radius: 5px;
  padding: 0 10px;

  transition: all 0.2s ease-out;
  border: 1px solid rgb(50, 50, 50);
}

.float-label .select-gender {
  display: flex;
  align-items: center;
  border: 0.5px solid rgb(50, 50, 50);
  height: auto;
  font-size: .9em;
  border-radius: 4px;
  background: #fff;
  padding: 5px;
}

.error-message {
  color: red;
  font-size: 0.9em;
}


.submit-button{
  padding: 10px;
}

.button-submit{
  /* outline: none; */
  margin: 3%;
  padding: 10px;
  background-color:  rgb(0, 170, 80);
  border: 2px solid rgb(0, 170, 80);
  border-radius: 3px;
  color: #fff;
  font-size: 1em;
  transition: all 0.3s;
}

.button-submit:hover{
  transform: scale(1.05);
  transition: all 0.3s;
  cursor: pointer;
}