
.view-links {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.view-links .address {
  display: flex;
  justify-content: flex-end;
  font-style: italic;
  color: rgb(0, 170, 80);
  font-size: 0.9em;
  gap: 5px;
}

a {
  color: rgb(0, 170, 80);
}
