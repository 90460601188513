.title{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1em 0;
  padding: 0.8em;
  border-bottom: 2px solid rgb(0, 170, 80);
}

.title span{
  color: rgb(50,50,50);
  margin-left: 0.8em;
  font-size: 1.5em;
  font-weight: bolder;
  font-style: italic;
}
.view-title{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.view-title .address{
  display: flex;
  justify-content: flex-end;
  font-style: italic;
  color: rgb(0, 170, 80);
  font-size: .9em;
}

a {
  color: rgb(0, 170, 80);
}